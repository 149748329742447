/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ExternalPageProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
const ExternalPage: React.FC<ExternalPageProps> = ({size, ...props}) => (
  <svg viewBox="0 0 20 20" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M4.07692 3.75C3.99022 3.75 3.90706 3.78444 3.84575 3.84575C3.78444 3.90706 3.75 3.99022 3.75 4.07692V15.9231C3.75 16.0098 3.78444 16.0929 3.84575 16.1542C3.90706 16.2156 3.99022 16.25 4.07692 16.25H15.9231C16.0098 16.25 16.0929 16.2156 16.1542 16.1542C16.2156 16.0929 16.25 16.0098 16.25 15.9231V11.0769C16.25 10.6627 16.5858 10.3269 17 10.3269C17.4142 10.3269 17.75 10.6627 17.75 11.0769V15.9231C17.75 16.4076 17.5575 16.8723 17.2149 17.2149C16.8723 17.5575 16.4076 17.75 15.9231 17.75H4.07692C3.59239 17.75 3.12771 17.5575 2.78509 17.2149C2.44248 16.8723 2.25 16.4076 2.25 15.9231V4.07692C2.25 3.59239 2.44248 3.12771 2.78509 2.78509C3.12771 2.44248 3.59239 2.25 4.07692 2.25H8.92308C9.33729 2.25 9.67308 2.58579 9.67308 3C9.67308 3.41421 9.33729 3.75 8.92308 3.75H4.07692ZM12.4808 3C12.4808 2.58579 12.8166 2.25 13.2308 2.25H17C17.2005 2.25 17.3825 2.32864 17.5171 2.45675C17.5262 2.46537 17.535 2.47422 17.5436 2.48328C17.6073 2.55021 17.6562 2.62602 17.6904 2.70659C17.7288 2.79671 17.75 2.89588 17.75 3V6.76923C17.75 7.18344 17.4142 7.51923 17 7.51923C16.5858 7.51923 16.25 7.18344 16.25 6.76923V4.81066L10.5303 10.5303C10.2374 10.8232 9.76256 10.8232 9.46967 10.5303C9.17678 10.2374 9.17678 9.76256 9.46967 9.46967L15.1893 3.75H13.2308C12.8166 3.75 12.4808 3.41421 12.4808 3Z"
      fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
ExternalPage.displayName = 'ExternalPage';
export default ExternalPage;
/* tslint:enable */
/* eslint-enable */
